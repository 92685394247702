import React from "react";
import ReactDOM from "react-dom";
import Resume from 'react-awesome-resume'

const  myJSONResume = require('./person.json');

ReactDOM.render(
    <Resume jsonResume={myJSONResume} theme={"default"} inline={false} />,
    document.getElementById("root")
);
